import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Col, Collapse, Row, Select, Spin, Tooltip} from "antd";
import TextArea from "antd/es/input/TextArea";
import {languageOptions} from "../components/translator/languageList";
import {CopyFilled, LoadingOutlined} from '@ant-design/icons';
import {Option} from "antd/lib/mentions";

function TranslatorPage() {

    const { t, i18n } = useTranslation();

    const [textInput, setTextInput] = useState("")
    const [textOutput, setTextOutput] = useState("")
    const [inputLan, setInputLang] = useState("")
    const [outputLan, setOutputLang] = useState("English")
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Kleiner Workaround, um zu vermeiden, dass der API Call doppelt getriggert wird, wenn die Ausgangssprache automatisch gesetzt wird
    const [inputLanSetManually, setInputLanSetManually] = useState(false)

    async function callTranslateApi() {
        const payload = {
            "text": textInput,
            "input_language": inputLan,
            "output_language": outputLan
        }

        try {
            let response = await fetch( window.origin + "/api/translation/translate", {
                method: "POST",
                body: JSON.stringify(payload)
            }).catch((error) => {
                if (error.message === "Failed to fetch") {
                    setShowAlert(true)
                }
            })

            const trans = await response.json();
            setTextOutput(trans.translation)
            setIsLoading(false)

            // Setze die Ausgangssprache, die erkannt wurde, falls nicht bisher eine gesetzt wurde
            if (inputLan === "") {
                setInputLang(trans.language)
                setInputLanSetManually(true)
            }
        } catch (error) {
            // throw new Error("Error requesting API:", error);
        }
    }

    useEffect(() => {
        if(textInput !== "" && outputLan !== "") {
            setIsLoading(true)
            let timeOutForApiCall = setTimeout(callTranslateApi, 500)

            return () => clearTimeout(timeOutForApiCall)
        } else if(textInput === "") {
            setTextOutput("")
        }
    }, [textInput])

    useEffect(() => {
        if(textInput !== "" && outputLan !== "" && !inputLanSetManually) {
            setIsLoading(true)
            callTranslateApi()
        } else {
            setInputLanSetManually(false)
        }
    }, [inputLan, outputLan])

    function onInputChanged(input) {
        setTextInput(input.target.value);
    }

    function onInputLangChanged(value) {
        setInputLang(value)
    }

    function onOutputLangChanged(value) {
        setOutputLang(value)
    }

    function onCopyClick() {
        navigator.clipboard.writeText(textOutput);
    }

    return (
        <div>
            <Alert
                message="Translation service not available"
                type="error"
                showIcon
                style={showAlert ? {display: 'flex'} : {display: 'none'}}
            />
            <Row>
                <Col
                    span={12}
                    style={{paddingRight: "5px"}}
                >
                    <Card
                        title={
                            <Select
                                allowClear={true}
                                onChange={onInputLangChanged}
                                options={languageOptions.map(lang => ({ label: lang, value: lang }))}
                                placeholder={t("translator.input.select")}
                                showSearch={true}
                                style={{
                                    width: 350,
                                }}
                                value={inputLan !== "" ? inputLan : null}
                            />
                        }
                        style={{height:"450px"}}
                    >
                        <TextArea
                            onChange={onInputChanged}
                            placeholder={t("translator.input.placeholder")}
                            rows={15}
                        />
                    </Card>
                </Col>
                <Col
                    span={12}
                    style={{paddingLeft: "5px"}}
                >
                    <Card
                        title={
                            <>
                                <Select
                                    defaultValue="English"
                                    onChange={onOutputLangChanged}
                                    options={languageOptions.map(lang => ({label: lang, value: lang}))}
                                    showSearch={true}
                                    style={{
                                        width: 350,
                                        marginRight: "10px"
                                    }}
                                />
                                <Tooltip title={t("translator.output.copy")}>
                                    <Button
                                        onClick={onCopyClick}
                                        shape="circle"
                                    >
                                        <CopyFilled
                                            style={{color: "#F58155"}}
                                        />
                                    </Button>
                                </Tooltip>
                            </>
                        }
                        style={{height: "450px"}}
                    >
                        {isLoading
                            ? <div
                                style={{textAlign: "center"}}
                            >
                                <Spin indicator={<LoadingOutlined spin/>} size="large"/>
                            </div>
                            : <p
                                style={{
                                    height: "300px",
                                    overflowY: "scroll",
                                    whiteSpace: "pre-line"
                                }}
                            >
                                {textOutput !== "" ? textOutput : t("translator.output.placeholder")}
                            </p>
                        }
                    </Card>
                </Col>
            </Row>
        </div>
    );

}

export default TranslatorPage;