import './App.css';
import React, {useEffect, useState} from 'react';
import { Layout } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import {hasAuthParams, useAuth} from "react-oidc-context";

const { Header, Content, Sider, Footer } = Layout;

function App({content, menu, header}) {
    //Localisation
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    function navigateHome() {
        navigate("/");
    }

    // Silent login if a user re-visits the page and is still logged in
    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect()
            setHasTriedSignin(true)
        }
        // eslint-disable-next-line
    }, [auth, hasTriedSignin])

  return (
      <div
          className='app'
          style={{ height: '100vh' }}
      >
          { !auth.isLoading && auth.isAuthenticated &&
            <Layout
                style={{ height: '100%' }}>
              <Header className="header"
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                    paddingLeft: "15px",
                }}
              >
                <div className="header-content">
                    <HomeOutlined
                        onClick={navigateHome}
                        style={{
                            fontSize: '38px',
                            color: "#F58155",
                            cursor: "pointer",
                        }}
                    />
                  {/*  <img*/}
                  {/*    className="header-image"*/}
                  {/*    src={"/abp_logo.png"}*/}
                  {/*    alt={"ABP Logo"}*/}
                  {/*    onClick={navigateHome}*/}
                  {/*    style={{*/}
                  {/*        cursor: "pointer",*/}
                  {/*    }}*/}
                  {/*/>*/}
                  {header && <img className="header-image-app" src={"/img/header/" + header} alt={""}></img>}
                  <h1 style={{color:'#f5835d'}}>{!header && <span>{t("title")}</span>}</h1>
                </div>
              </Header>
              <Layout>
                <Layout style={{ padding: '0 24px 24px' }}>
                  <Content
                      className="site-layout-background"
                      style={{
                        padding: 24,
                        marginLeft: 10,
                        paddingBottom: 80,
                        marginTop: 64,
                        marginBottom:64,
                        minHeight: 280,
                      }}
                  >
                    {content}
                  </Content>
                </Layout>
              </Layout>
              <Footer>&copy; {new Date().getFullYear()} ABP Induction</Footer>
            </Layout>
          }
      </div>
  );
}

export default App;

