import React from "react";
import {Card, Badge, Tooltip} from 'antd';
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import './LinkCard.css';

const { Meta } = Card;

function LinkCard({name, link, logo, description, internal}) {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const openApp = () => {
        if (internal) {
            navigate(link);
        } else {
            window.open(link, "_blank");
        }
    }

    return (
        <div onClick={() => openApp()}>
            <Tooltip
                placement="bottom"
                title={t('links.tooltip.' + description)}
                color={"#F58155"}
            >
                <Card
                    hoverable
                    className="greyscale"
                    style={{
                        width: 200,
                        height: 210,
                        padding: 5,
                        margin: 5,
                    }}
                    cover={
                        <div
                            className="logo-container"
                            style={{
                                textAlign: "center",
                                minHeight: "155px"
                            }}
                        >
                            <img
                                className="logo-image"
                                alt={name}
                                src={"/img/logo/" + logo}
                            />
                        </div>
                    }
                >
                    <Meta title={name}/>
                </Card>
            </Tooltip>
        </div>
    );
}



export default LinkCard;